<template>
    <!-- eslint-disable max-len -->
    <div class="more-info-CustomerService bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheDefaultHeader :back="true" />

        <main v-if="contact" class="more-info-CustomerService_Content mx-4 text-center">
            <h2 class="mb-2">{{ $t('more.need-help') }}</h2>
            <p v-if="contact.intro" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis w-10/12 mx-auto">
                {{ contact.intro }}
            </p>

            <div class="more-info-CustomerService_Buttons mt-8 grid row-gap-2">
                <a v-if="contact.phone_number" :href="`tel:${contact.phone_number}`" :aria-label="contact.phone_number" class="block">
                    <AppButton type="ghost" icon="phone" size="small" width="full">{{ contact.phone_number }}</AppButton>
                </a>

                <a v-if="contact.email" :href="`mailto:${contact.email}`" :aria-label="contact.email" class="block">
                    <AppButton type="ghost" icon="envelop" size="small" width="full">{{ contact.email }}</AppButton>
                </a>
            </div>
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import { onBeforeMount } from '@vue/composition-api';
import useReseller from '@/composables/useReseller';

export default {
    name: 'MoreInfoCustomerService',

    components: {
        TheDefaultHeader,
        AppButton,
        TheBottomMenu,
    },

    setup() {
        const { fetchResellerData, resellerDataFetched, contact } = useReseller();

        onBeforeMount(async () => {
            if (!resellerDataFetched.value) {
                await fetchResellerData();
            }
        });

        return {
            contact,
        };
    },
};
</script>

<style lang="scss" scoped>
.more-info-CustomerService {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}
</style>
