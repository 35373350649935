<template>
    <!-- eslint-disable max-len -->
    <div class="more-info-PrivacyPolicy bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheDefaultHeader :back="true" />

        <main class="more-info-PrivacyPolicy_Content mx-4">
            <header class="flex items-center mb-2">
                <AppIcon icon-name="shield" :width="24" :height="24" />
                <h1 class="ml-2">Privacy Policy</h1>
            </header>

            <AppContentBlock :content="page" />
        </main>

        <TheBottomMenu />
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import AppContentBlock from '@/components/Interface/AppContentBlock.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import useReseller from '@/composables/useReseller';
import { onBeforeMount } from '@vue/composition-api';

export default {
    name: 'MoreInfoPrivacyPolicy',

    components: {
        TheDefaultHeader,
        AppIcon,
        AppContentBlock,
        TheBottomMenu,
    },

    setup() {
        const { fetchResellerData, resellerDataFetched, privacyPolicy } = useReseller();

        onBeforeMount(async () => {
            if (!resellerDataFetched.value) {
                await fetchResellerData();
            }
        });

        return {
            page: privacyPolicy,
        };
    },
};
</script>

<style lang="scss">
.more-info-PrivacyPolicy {

    &_Content {
        margin-bottom: calc(84px + 60px); // Dock menu + toolbar
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 70px; // Dock menu
        height: 120px;
        width: 100%;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        border-radius: 0px 0px 24px 24px;
        pointer-events: none;
    }
}

.mode-dark .more-info-PrivacyPolicy {

    &::after {
        background: linear-gradient(0deg, #232323 0%, rgba(0, 0, 0, 0) 100%);
    }
}
</style>
