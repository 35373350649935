/* eslint-disable max-len */
import { reactive, toRefs } from '@vue/composition-api';
import $http from 'axios';

const state = reactive({
    resellerDataFetched: false,
    contact: null,
    privacyPolicy: null,
    termsAndConditions: null,
});

export default function useReseller() {
    /**
     * Method to fetch order history data
     *
     * @async
     * @returns {void}
     */
    const fetchResellerData = async () => {
        try {
            const response = await $http.get('/info');
            state.contact = response.data.contact;
            state.privacyPolicy = response.data.pages.privacy;
            state.termsAndConditions = response.data.pages.terms_conditions;

            state.resellerDataFetched = true;
        } catch (error) {
            console.log(error);
        }
    };

    return {
        ...toRefs(state),
        fetchResellerData,
    };
}
