<template>
    <article class="ui-AppContentBlock">
        <vue-markdown>{{ content }}</vue-markdown>
    </article>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
    name: 'UIAppContentBlock',

    props: {
        content: String,
    },

    components: {
        VueMarkdown,
    },
};
</script>

<style lang="scss">
.ui-AppContentBlock {

    p {
        color: theme('colors.day-on-surface.medium-emphasis');
        margin-bottom: 1.25rem;
    }
}

.mode-dark .ui-AppContentBlock {

    p {
        color: theme('colors.night-on-surface.medium-emphasis') !important;
    }
}
</style>
