<template>
    <!-- eslint-disable max-len -->
    <div
        @click="$root.$i18n.locale !== lang ? $emit('selected', lang) : null"
        class="LanguageSwitchItem flex items-center bg-day-04dp dark:bg-night-04dp rounded-xl p-4 cursor-pointer"
        :class="{ 'opacity-50 cursor-default': comingSoon || $root.$i18n.locale === lang }">

        <AppIcon :icon-name="`flag-${lang}`" :width="24" :height="24" />
        <span class="ml-2">{{ label }} {{ $root.$i18n.locale === lang ? $t('account.language.selected') : '' }} <span v-if="comingSoon" class="text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis ml-2">{{ $t('account.language.coming-soon') }}</span> </span>
    </div>
</template>

<script>
import AppIcon from '@/components/Interface/AppIcon.vue';

export default {
    name: 'LanguageSwitchItem',

    props: {
        lang: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        comingSoon: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        AppIcon,
    },
};
</script>

<style lang="scss" scoped>
.LanguageSwitchItem {}
</style>
