<template>
    <!-- eslint-disable max-len -->
    <div class="MoreInfo bg-day-01dp dark:bg-night-01dp h-full overflow-y-auto">
        <TheOrderHeader v-if="connectedToMachine" />
        <TheDefaultHeader v-else />

        <main class="MoreInfo_Content mx-4">
            <nav class="MoreInfo_Menu grid row-gap-2">
                <RouterLink v-if="contact" :to="{ name: 'CustomerService' }">
                    <AppMenuItem :label="$t('more.customer-service')">
                        <template #icon><SVGCustomerService /></template>
                    </AppMenuItem>
                </RouterLink>

                <a :href="$root.$i18n.locale === 'nl' ? 'https://www.junea.nl/faq-app' : 'https://www.junea.nl/en/faq-app'" target="_blank">
                    <AppMenuItem :label="$t('more.faq')">
                        <template #icon><SVGFrequentlyAskedQuestions /></template>
                    </AppMenuItem>
                </a>

                <RouterLink v-if="privacyPolicy" :to="{ name: 'PrivacyPolicy' }">
                    <AppMenuItem :label="$t('more.privacy-policy')">
                        <template #icon><SVGPrivacyPolicy /></template>
                    </AppMenuItem>
                </RouterLink>

                <RouterLink v-if="termsAndConditions" :to="{ name: 'TermsConditions' }">
                    <AppMenuItem :label="$t('more.terms-and-conditions')">
                        <template #icon><SVGTermsConditions /></template>
                    </AppMenuItem>
                </RouterLink>

                <AppMenuItem @click="toggleLanguageDrawer" :label="$t('account.menu.language')">
                    <template #icon><SVGLanguage /></template>
                </AppMenuItem>

                <AppMenuItem :label="$t('more.darkmode')">
                    <template #icon><SVGDarkmode /></template>

                    <template #right>
                        <AppSwitch :value="darkModeEnabled" @input="toggleDarkMode($event)" />
                    </template>
                </AppMenuItem>
            </nav>

            <div class="MoreInfo_Version mt-6 text-center">
                <p class="text-day-on-surface-low-emphasis-disabled dark:text-night-on-surface-low-emphasis-disabled">
                    {{ $t('more.app-version') }} {{ appVersion }}
                </p>
            </div>
        </main>

        <TheBottomMenu />

        <portal to="drawers">
            <LanguageSwitchDrawer v-if="languageDrawerEnabled" @close="toggleLanguageDrawer" />
        </portal>
    </div>
</template>

<script>
import TheDefaultHeader from '@/components/Global/TheDefaultHeader.vue';
import TheOrderHeader from '@/components/Global/TheOrderHeader.vue';
import AppMenuItem from '@/components/Interface/AppMenuItem.vue';
import AppSwitch from '@/components/Interface/AppSwitch.vue';
import TheBottomMenu from '@/components/Global/TheBottomMenu.vue';
import LanguageSwitchDrawer from '@/components/Drawers/LanguageSwitch.vue';
import SVGCustomerService from '@/assets/svg/menu-customer-service.svg?inline';
import SVGFrequentlyAskedQuestions from '@/assets/svg/menu-frequently-asked-questions.svg?inline';
import SVGPrivacyPolicy from '@/assets/svg/menu-privacy-policy.svg?inline';
import SVGTermsConditions from '@/assets/svg/menu-terms-conditions.svg?inline';
import SVGLanguage from '@/assets/svg/menu-language.svg?inline';
import SVGDarkmode from '@/assets/svg/menu-darkmode.svg?inline';
import useReseller from '@/composables/useReseller';
import useMachine from '@/composables/useMachine';
import { onBeforeMount, ref } from '@vue/composition-api';

const useLanguageSwitcher = () => {
    const languageDrawerEnabled = ref(false);

    const toggleLanguageDrawer = () => {
        languageDrawerEnabled.value = !languageDrawerEnabled.value;
    };

    return {
        languageDrawerEnabled,
        toggleLanguageDrawer,
    };
};

const useDarkMode = () => {
    const darkModeEnabled = ref(false);

    if (document.documentElement.classList.contains('mode-dark')) {
        darkModeEnabled.value = true;
    }

    const toggleDarkMode = (value) => {
        if (value) {
            document.documentElement.classList.add('mode-dark');
            localStorage.setItem('preferred-color-scheme', 'dark');
        } else {
            document.documentElement.classList.remove('mode-dark');
            localStorage.setItem('preferred-color-scheme', 'light');
        }
    };

    return {
        darkModeEnabled,
        toggleDarkMode,
    };
};

export default {
    name: 'MoreInfo',

    components: {
        TheDefaultHeader,
        TheOrderHeader,
        AppMenuItem,
        AppSwitch,
        TheBottomMenu,
        LanguageSwitchDrawer,
        SVGCustomerService,
        SVGFrequentlyAskedQuestions,
        SVGPrivacyPolicy,
        SVGTermsConditions,
        SVGLanguage,
        SVGDarkmode,
    },

    setup() {
        const { machine: connectedToMachine } = useMachine();

        const {
            fetchResellerData,
            privacyPolicy,
            contact,
            termsAndConditions,
            resellerDataFetched,
        } = useReseller();

        const appVersion = process.env.PACKAGE_VERSION || '0';

        onBeforeMount(async () => {
            if (!resellerDataFetched.value) {
                await fetchResellerData();
            }
        });

        return {
            appVersion,
            ...useLanguageSwitcher(),
            ...useDarkMode(),
            privacyPolicy,
            termsAndConditions,
            contact,
            connectedToMachine,
        };
    },
};
</script>

<style lang="scss" scoped>
.MoreInfo {

    &_Content {
        margin-bottom: 84px; // Dock menu + toolbar
    }
}

.mode-dark .MoreInfo {

    .layout-TheDefaultHeader,
    .layout-TheOrderHeader {
        @apply bg-night-01dp;
    }
}
</style>
